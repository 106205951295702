<template>
  <div class="slick-carousel-wrapper">
    <VueSlickCarousel
      :arrows="settings.arrows"
      :dots="settings.dots"
      :autoplay="settings.autoplay"
      :autoplaySpeed="settings.autoplaySpeed"
      :useTransform="settings.useTransform"
      :adaptiveHeight="settings.adaptiveHeight"
    >
      <div v-for="(image, index) in images" :key="index">
        <img
          :src="require(`@/assets/${image.src}`)"
          :alt="'Image ' + (index + 1)"
          class="img-slider"
          width="100%"
        />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Slider",
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: false,
        dots: true,
        infinite: false,
        autoplay: true,
        autoplaySpeed: 5000,
        useTransform: true,
        adaptiveHeight: true,
        centerMode: true,
        centerPadding: "0px",
      },
      images: [
        {
          src: "OPI-Banner-01.jpg",
        },
        {
          src: "OPI-Banner-02.jpg",
        },
        {
          src: "OPI-Banner-03.jpg",
        },
        {
          src: "OPI-Banner-04.jpg",
        },
        {
          src: "OPI-Banner-05.jpg",
        },
      ],
    };
  },
};
</script>

<style scoped>
::v-deep .slick-prev::before,
::v-deep .slick-next::before {
  font-size: 25px;
}
::v-deep .slick-prev {
  left: 10px !important;
  z-index: 1;
}

::v-deep .slick-next {
  right: 10px !important;
  z-index: 1;
}
::v-deep .slick-dots {
  position: absolute;
  bottom: -20px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  display: flex !important;
  justify-content: flex-end; /* Ensure dots align to the right */
}

::v-deep .slick-dots li {
  margin: 0 5px; /* Adjust spacing between dots */
}

::v-deep .slick-dots li button {
  width: 15px;
  height: 15px;
}

::v-deep .slick-dots li button:before {
  content: ""; /* Remove default dot content */
  background-color: #114e87; /* Default dot color */
  display: block;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

::v-deep .slick-dots li.slick-active {
  background-color: #114e87; /* Active dot color */
}

::v-deep .slick-dots li button:before {
  box-shadow: 0 0 5px rgba(248, 2, 2, 0.3); /* Add shadow for depth */
}

@media screen and (min-width: 768px) {
  .img-slider {
    max-height: 600px;
  }
}
@media only screen and (max-width: 767.98px) {
  ::v-deep .slick-dots li {
    margin: 0px; /* Adjust spacing between dots */
  }

  ::v-deep .slick-dots li button:before,
  ::v-deep .slick-dots li.slick-active {
    width: 10px !important;
    height: 10px !important;
    margin-right: 10px;
  }
}

</style>
